import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Vuelidate from 'vuelidate'
import VueSweetalert2 from 'vue-sweetalert2';
import VueMask from 'v-mask'
// import * as Sentry from "@sentry/vue";

import vco from "v-click-outside"

import router from './router'
import store from '@/state/store'
import i18n from './i18n'

import "@/assets/scss/app.scss";

Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(vco)
Vue.use(Vuelidate)
Vue.use(VueSweetalert2);
Vue.use(VueMask)
Vue.use(require('vue-chartist'))
Vue.component('apexchart', VueApexCharts)


// Sentry.init({
//   Vue,
//   dsn: "https://b25849bfab16d2c8d5cf762fb2c235d7@o4505119880708096.ingest.sentry.io/4505985290797056",
//   integrations: [
//     new Sentry.BrowserTracing({
//       routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//     }),
//     new Sentry.Replay(),
//   ],
//
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
//
//   // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled   http://w3e.localhost:8080/
//   tracePropagationTargets: ["localhost", /^https:\/\/workany\.io/],
//
//   // Capture Replay for 10% of all sessions,
//   // plus for 100% of sessions with an error
//   replaysSessionSampleRate: 0.1,
//   replaysOnErrorSampleRate: 1.0,
// });


new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')











