let store = localStorage

class Preference {

    static KEY_IS_LOGGED_IN = 'key_is_logged_in'
    static KEY_CACHE_DIRECTORY = 'key_cache_directory'
    static KEY_DEEPLINK_DATA = 'key_deeplink_data'

    static KEY_USER_ID = 'key_user_id'
    static KEY_NAME = 'key_name'
    static KEY_EMAIL = 'key_email'
    static KEY_ACCESS_TOKEN = 'key_access_token'
    static KEY_ACCESS_TOKEN_EXPIRY = 'access_token_expires_at'
    static KEY_REFRESH_TOKEN = 'key_refresh_token'
    static KEY_REFRESH_TOKEN_EXPIRY = 'refresh_token_expires_at'
    static KEY_PHONE = 'key_phone'
    static KEY_IMAGE = 'key_image'
    static KEY_AUTH_TYPE = 'key_auth_type'
    static KEY_PUSH_ID = 'key_push_id'
    static KEY_ROLE_ID = 'key_role_id'
    static KEY_ACCESS_PERMISSION = 'key_access_permission'
    static KEY_CLIENT_ID = 'key_client_id'
    static KEY_AUTO_REFRESH_TIME = 'key_auto_refresh_time'
    static KEY_AUTO_REFRESH_KEY = 'key_auto_refresh_key'
    static KEY_AUTO_REFRESH_VIEW = 'key_auto_refresh_view'
    static KEY_JIRA_ACCESS_TOKEN = 'key_jira_access_token'
    static KEY_JIRA_ACCESS_TOKEN_SECRET = 'key_jira_access_token_secret'
    static KEY_JIRA_OAUTH_VERIFIER = 'key_jira_oauth_verifier'
    static KEY_JIRA_HOST_URL = 'key_jira_host_url'
    static KEY_COMPANY_LOGO_URL = 'key_company_logo_url'

    constructor() {
        store = localStorage
    }

    setData(key, value) {
        store.setItem(key, value)
    }

    getData(key) {
        return store.getItem(key)
    }

    clearData(key) {
        store.removeItem(key)
    }

    deleteProfileData() {
        this.clearData(Preference.KEY_USER_ID)
        this.clearData(Preference.KEY_NAME)
        this.clearData(Preference.KEY_EMAIL)
        this.clearData(Preference.KEY_ACCESS_TOKEN)
        this.clearData(Preference.KEY_ACCESS_TOKEN_EXPIRY)
        this.clearData(Preference.KEY_REFRESH_TOKEN)
        this.clearData(Preference.KEY_REFRESH_TOKEN_EXPIRY)
        this.clearData(Preference.KEY_PHONE)
        this.clearData(Preference.KEY_IMAGE)
        this.clearData(Preference.KEY_AUTH_TYPE)
        this.clearData(Preference.KEY_PUSH_ID)
        this.clearData(Preference.KEY_ROLE_ID)
        this.clearData(Preference.KEY_CLIENT_ID)
        this.clearData(Preference.KEY_ACCESS_PERMISSION)
    }

    setProfileData(data) {
        this.setData(Preference.KEY_USER_ID, data.user_id)
        this.setData(Preference.KEY_NAME, data.name)
        this.setData(Preference.KEY_EMAIL, data.email)
        this.setData(Preference.KEY_ACCESS_TOKEN, data.access_token)
        this.setData(Preference.KEY_ACCESS_TOKEN_EXPIRY, data.access_token_expires_at)
        this.setData(Preference.KEY_REFRESH_TOKEN, data.refresh_token)
        this.setData(Preference.KEY_REFRESH_TOKEN_EXPIRY, data.refresh_token_expires_at)
        this.setData(Preference.KEY_PHONE, data.phone)
        this.setData(Preference.KEY_IMAGE, data.image)
        this.setData(Preference.KEY_AUTH_TYPE, data.auth_type)
        this.setData(Preference.KEY_PUSH_ID, data.push_id)
        this.setData(Preference.KEY_ROLE_ID, data.role_id)
        this.setData(Preference.KEY_ACCESS_PERMISSION, JSON.stringify(data.userAccessPermission))
    }

    getProfileData() {
        return {
            userId: this.getData(Preference.KEY_USER_ID),
            name: this.getData(Preference.KEY_NAME),
            email: this.getData(Preference.KEY_EMAIL),
            accessToken: this.getData(Preference.KEY_ACCESS_TOKEN),
            accessTokenExpiresAt: this.getData(Preference.KEY_ACCESS_TOKEN_EXPIRY),
            refreshToken: this.getData(Preference.KEY_REFRESH_TOKEN),
            refreshTokenExpiresAt: this.getData(Preference.KEY_REFRESH_TOKEN_EXPIRY),
            phone: this.getData(Preference.KEY_PHONE),
            image: this.getData(Preference.KEY_IMAGE),
            type: this.getData(Preference.KEY_AUTH_TYPE),
            pushId: this.getData(Preference.KEY_PUSH_ID),
            roleId: this.getData(Preference.KEY_ROLE_ID),
            clientId: this.getData(Preference.KEY_CLIENT_ID)
        }
    }

    setAuthData(data) {
        this.setData(Preference.KEY_ACCESS_TOKEN, data.access_token)
        this.setData(Preference.KEY_ACCESS_TOKEN_EXPIRY, data.access_token_expires_at)
        this.setData(Preference.KEY_REFRESH_TOKEN, data.refresh_token)
        this.setData(Preference.KEY_REFRESH_TOKEN_EXPIRY, data.refresh_token_expires_at)
    }
}

module.exports = Preference
