// import store from '@/state/store'
import Preference from "@/data/Preference"

let preference = new Preference()

export default [
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/pages/account/login'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (preference.getData("key_user_id")) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('../views/pages/account/register'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // Get the current hostname from the URL
                const hostname = window.location.hostname;

                // Check if the hostname has a subdomain
                const hasSubdomain = hostname.split('.').length > 1;

                // If the user is already logged in or there is no subdomain
                if (preference.getData("key_user_id") || hasSubdomain) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/forgot-password',
        name: 'Forgot-password',
        component: () => import('../views/pages/account/forgot-password'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (preference.getData("key_user_id")) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/forgot-workspace',
        name: 'Forgot-workspace',
        component: () => import('../views/pages/account/forgot-workspace'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (preference.getData("key_user_id")) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/change-password',
        name: 'change-password',
        meta: {
            authRequired: false,
        },
        component: () => import('../views/pages/account/password-reset'),

    },
    {
        path: '/create-organization',
        name: 'create-organization',
        meta: {
            authRequired: false,
        },
        component: () => import('../views/pages/account/create-organization'),

    },
    {
        path: '/tasks',
        name: 'tasks',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/dashboard/tasks'),

    },
    {
        path: '/apps',
        name: 'apps',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/dashboard/apps'),

    },
    {
        path: '/urls',
        name: 'urls',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/dashboard/urls'),

    },
    {
        path: '/apps/users',
        name: 'users',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/dashboard/app-used-users'),

    },
    {
        path: '/activities',
        name: 'activities',
        props: route => ({ queryId: route.query.id, queryName: route.query.name , queryDate: route.query.date }),
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/dashboard/activities'),
    },

    {
        path: '/departments',
        name: 'departments',
        meta: {
            authRequired: false,
            // beforeResolve(routeTo, routeFrom, next) {
            //     if (!['2', '3', '4', '6', '7'].includes(preference.getData(Preference.KEY_ROLE_ID))) {
            //         console.log(preference.getData(Preference.KEY_ROLE_ID))
            //         next({name: 'home'})
            //     } else {
            //         // Do nothing
            //         next()
            //     }
            // },
        },
        component: () => import('../views/pages/dashboard/organizations'),

    }, {
        path: '/download',
        name: 'download',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/dashboard/download')
    },
    {
        path: '/verify-email',
        name: 'verify-email',
        props: true,
        meta: {
            authRequired: false,
        },
        component: () => import('../views/pages/account/email-verification'),
    },
    {
        path: '/password-reset-request',
        name: 'password-reset-request',
        props: true,
        meta: {
            authRequired: false,
        },
        component: () => import('../views/pages/account/password-reset-request'),

    },
    {
        path: '/authorization-failed',
        name: 'authorization-failed',
        props: true,
        meta: {
            authRequired: false,
        },
        component: () => import('../views/pages/account/authorization-failed'),

    },
    {
        path: '/reset_authorization-failed',
        name: 'reset_authorization-failed',
        props: true,
        meta: {
            authRequired: false,
        },
        component: () => import('../views/pages/account/reset_authorization-failed'),

    },
    {
        path: '/projects',
        name: 'projects',
        props: true,
        meta: {
            authRequired: true,
            // beforeResolve(routeTo, routeFrom, next) {
            //     if (!['2', '3', '4', '6', '7'].includes(preference.getData(Preference.KEY_ROLE_ID))) {
            //         console.log(preference.getData(Preference.KEY_ROLE_ID))
            //         next({name: 'home'})
            //     } else {
            //         // Do nothing
            //         next()
            //     }
            // },
        },
        component: () => import('../views/pages/dashboard/projects'),
    },
    {
        path: '/logout',
        name: 'logout',
        meta: {
            authRequired: false,
            beforeResolve(routeTo, routeFrom, next) {
                if (preference.getData("key_user_id")) {
                    preference.deleteProfileData()
                } else {
                    // Do nothing
                }
                next({ name: 'home' })
                // const authRequiredOnPreviousRoute = routeFrom.matched.some(
                //     (route) => route.push('/login')
                // )
                // Navigate back to previous page, or home as a fallback
                // next(authRequiredOnPreviousRoute ? { name: 'home' } : { ...routeFrom })
            },
        },
    },
    {
        path: '/',
        name: 'home',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/dashboard/index')
    },
    {
        path: '/pages/starter',
        name: 'Starter Page',
        meta: { authRequired: true },
        component: () => import('../views/pages/utility/starter')
    },
    {
        path: '/pages/maintenance',
        name: 'Maintenance',
        meta: { authRequired: true },
        component: () => import('../views/pages/utility/maintenance')
    },
    {
        path: '/pages/coming-soon',
        name: 'Coming-soon',
        meta: { authRequired: true },
        component: () => import('../views/pages/utility/coming-soon')
    },
    {
        path: '/pages/faqs',
        name: 'Faqs',
        meta: { authRequired: true },
        component: () => import('../views/pages/utility/faqs')
    },
    {
        path: '/pages/error-404',
        name: 'Error-404',
        meta: { authRequired: true },
        component: () => import('../views/pages/utility/error-404')
    },
    {
        path: '/pages/error-500',
        name: 'Error-500',
        meta: { authRequired: true },
        component: () => import('../views/pages/utility/error-500')
    },
    {
        path: '/report',
        name: 'report',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/dashboard/report')
    },
    {
        path: '/integration',
        name: 'integration',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/dashboard/integration')
    },
    {
        path: '/members',
        name: 'members',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/dashboard/members-management')
    },
    {
        path: '/requests',
        name: 'requests',
        meta: {
            authRequired: false,
        },
        component: () => import('../views/pages/dashboard/requests')
    },
    {
        path: '/loading-integration',
        name: 'Loading',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/dashboard/loading')
    },
    {
        path: '/company',
        name: 'company',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                if (!['2', '3', '4', '6', '7'].includes(preference.getData(Preference.KEY_ROLE_ID))) {
                    console.log(preference.getData(Preference.KEY_ROLE_ID))
                    next({ name: 'home' })
                } else {
                    // Do nothing
                    next()
                }
            },
        },
        component: () => import('../views/pages/dashboard/work-space')
    },
    {
        path: '/profile',
        name: 'Profile',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/dashboard/profile')
    },
]
